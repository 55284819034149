import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';
import TextInput from './components/TextInput';
import BottomPanel from '../../components/BottomPanel';
import StyledButton from '../../components/StyledButton';
import Dialog from '../../components/Dialog';
import { navigationBarHeight } from '../../components/Layout';
import validator from './validator';
import { UserAPI } from '../../apis/UserAPI';
import { useEffect } from 'react';

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const errorMessage = useRef();

  const nextStep = async () => {
    const isValid = checkPasswordValidation();
    if (isValid) {
      const result = await resetPassword()
      if (result) {
        setShowSuccessDialog(true);
      }
    }
  };

  const checkPasswordValidation = () => {
    if (!validator.isPassword(password, errors)) {
      setShowDialog(true);
      errorMessage.current = errors.password;
      return false;
    }
    if (!validator.isPasswordConfirm(password, confirmPassword, errors)) {
      setShowDialog(true);
      errorMessage.current = errors.passwordConfirm;
      return false;
    }
    return true;
  };

  const resetPassword = async () => {
    const payload = { password: password, passwordConfirm: confirmPassword };
    const response = await UserAPI.resetPassword(payload);
    if (response?.error) {
      setShowDialog(true);
      errorMessage.current = response?.error?.message;
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (state?.entry !== "/resetPassword/otp" && state?.entry !== "/resetPassword/identity") {
      navigate("/signIn")
    }
  }, [navigate, state])

  return (
    <Box sx={{ p: `${navigationBarHeight}px 24px 0`, height: '75vh' }}>
      <NavigationBar
        title="請輸入帳號使用的Email"
        leftButtonHandler={() => navigate('/signIn')}
      />
      <Stack spacing={4} pt="16px">
        <TextInput
          id="new-pwd"
          inputLabel="設定新密碼"
          placeholder=""
          type="password"
          background="#FFF"
          value={password}
          setState={setPassword}
        />
        <TextInput
          id="confirm-new-pwd"
          inputLabel="確認新密碼"
          placeholder=""
          type="password"
          background="#FFF"
          value={confirmPassword}
          setState={setConfirmPassword}
        />
      </Stack>
      <BottomPanel noShadow>
        <StyledButton
          variant="contained"
          fullWidth
          disabled={false}
          disableElevation
          onClick={nextStep}>
          重置密碼
        </StyledButton>
      </BottomPanel>
      <Dialog
        key="error"
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title="重置密碼錯誤"
        message={errorMessage.current}
        actionLabel="確定"
        actionHandler={() => setShowDialog(false)}
      />
      <Dialog
        key="success"
        showDialog={showSuccessDialog}
        setShowDialog={setShowSuccessDialog}
        title="重設密碼成功"
        message="您已完成重設密碼，請重新登入!"
        actionLabel="確定"
        actionHandler={() => navigate("/signIn")}
      />
    </Box>
  );
}
