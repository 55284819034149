import React from 'react';
import { Box } from '@mui/material';
import Panel from '../Panel';
import Icon from '../Icon';
import {
  SuccessStatusBox,
  PendingStatusBox,
  CancelStatusBox,
} from '../StatusBox';
import {
  Label,
  SubTitleLabel,
  SuccessLabel,
  PendingLabel,
  CancelLabel,
} from '../Label';
import {
  PriceNumberFormat,
  ThousandSeparator,
} from '../../utils/CoinNumberFormat';

const StatusBoxDict = {
  success: (
    <SuccessStatusBox>
      <SuccessLabel>已成功</SuccessLabel>
    </SuccessStatusBox>
  ),
  pending: (
    <PendingStatusBox>
      <PendingLabel>待確認</PendingLabel>
    </PendingStatusBox>
  ),
  cancel: (
    <CancelStatusBox>
      <CancelLabel>已取消</CancelLabel>
    </CancelStatusBox>
  ),
};

export default function AssetRecordCell({
  onClick,
  status,
  side,
  product,
  price,
  time,
}) {
  return (
    <Panel gutterBottom>
      <Box
        alignItems="center"
        justifyContent="space-between"
        height="72px"
        display="flex"
        padding="16px"
        onClick={onClick}>
        {/* 貨幣圖示 */}
        <Icon width="30px" height="30px" src={`/images/${product}.png`} />

        {/* 買入 / 賣出 */}
        <Box sx={{ p: '0 8px' }}>
          {side === 'buy' ? (
            <Label fontSize="15px" fontWeight="bold" color="#00C265">
              買入
            </Label>
          ) : (
            <Label fontSize="15px" fontWeight="bold" color="#FE3659">
              賣出
            </Label>
          )}

          <SubTitleLabel fontSize="11px" fontWeight="regular" minWidth="95px">
            {time?.slice(0, -3)}
          </SubTitleLabel>
        </Box>
        {/* 數量 */}
        <Box sx={{ minWidth: '120px', p: '0 8px' }}>
          <Label
            align="right"
            fontSize="15px"
            fontWeight="medium"
            color="#343A40">
            {ThousandSeparator(price)} {product}
          </Label>
        </Box>

        {/* 訂單狀態 */}
        {StatusBoxDict[status]}
      </Box>
    </Panel>
  );
}
